import React from "react";
import BuySell from "../shared/BuySell";

const BuyingCurrency = () => {
    return (
        <div className="">
            <div className="">
                <h1 className="text-headings text-3xl font-medium mt-10">Buying Currency</h1>
                <BuySell  payment={false}/>
            </div>
        </div>
    )
}

export default BuyingCurrency;