import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
    Button,
    Label,
    Radio,
    Select,
    TextInput,
    Modal,
} from 'flowbite-react';
import { toast } from 'react-toastify';
import { ImCross } from "react-icons/im";
import { roundValue } from '../utils/utils';
import axios from 'axios';

const PaymentInfo = ({ showPayment, setPaymentMethod, setPaymentAmount }) => {
    if (showPayment) {
        return (
            <div className="grid grid-cols-3">
                <div className="col-span-2 flex items-center">
                    <div className="p-2 w-1/4">
                        <Label htmlFor="payment" value="Mode of payment" />
                    </div>
                    <div className='w-1/2'>
                        <Select id="payment" sizing='lg' defaultValue={'DEFAULT'} onChange={(event) => { setPaymentMethod(event.target.options[event.target.selectedIndex].innerText); }} required>
                            <option className='text-xl' value="DEFAULT" disabled>Select</option>
                            <option className='text-xl'>Cash</option>
                            <option className='text-xl'>Credit Card</option>
                            <option className='text-xl'>Bank Transfer</option>
                            <option className='text-xl'>Others</option>
                        </Select>
                    </div>
                </div>
                {/* <div className="flex items-center">
                    <div className="p-2">
                        <Label htmlFor="payment-value" value="Value" />
                    </div>
                    <div className='p-2 w-1/2'>
                        <input type="number" name="payment-value" onChange={(event) => setPaymentAmount(event.target.value)} className='bg-gradient-to-br from-theme-primary to-theme-secondary rounded-lg text-md text-white' />
                    </div>
                </div> */}
            </div>
        )
    }
}

const Invoice = React.forwardRef(({ transactions, totalPayable, handleSubmit, handleSaveAndPrint, setOpenModal }, ref) => {
    const todayDate = new Date();
    const year = todayDate.getFullYear();
    const month = (todayDate.getMonth() + 1).toString().padStart(2, '0');
    const day = todayDate.getDate().toString().padStart(2, '0');
    const hours = todayDate.getHours() > 12 ? (todayDate.getHours() - 12).toString().padStart(2, '0') : todayDate.getHours().toString().padStart(2, '0');
    const minutes = todayDate.getMinutes().toString().padStart(2, '0');
    const ampm = todayDate.getHours() >= 12 ? 'PM' : 'AM';

    return (
        <div ref={ref} className="space-y-10 invoice-to-print">
            <ImCross className='ml-auto cross-icon' onClick={() => setOpenModal(false)} />
            {transactions.map((item, index) => (
                <div key={index} className="space-y-3">
                    <h2 className='text-center font-bold text-xl'>Tax Invoice</h2>
                    <div className="grid grid-cols-2 text-sm">
                        <div className='print-bold'>
                            Date: {`${day}/${month}/${year}`}
                        </div>
                        <div className="text-right mr-4 print-bold">
                            Time: {`${hours}:${minutes} ${ampm}`}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 font-medium mx-4 border-b pb-3 border-black">
                        <div className='-ml-4 print-bold'>
                            ACN:
                        </div>
                        <div className="text-right text-headings print-bold">
                            14660270913
                        </div>
                    </div>
                    <div className="grid grid-cols-2 font-medium mx-4 border-b pb-3 border-black">
                        <div className='-ml-4 print-bold'>
                            Shop Name:
                        </div>
                        <div className="text-right text-headings print-bold">
                            63 Swanstom ST,
                        </div>
                    </div>
                    <div className="grid grid-cols-2 font-medium mx-4 border-b pb-3 border-black">
                        <div className='-ml-4 print-bold'>
                            Transaction #:
                        </div>
                        <div className="text-right text-headings print-bold">
                            #
                        </div>
                    </div>
                    <div className="grid grid-cols-2 font-medium mx-4 border-b pb-3 border-black">
                        <div className='-ml-4 print-bold'>
                            Transaction Type:
                        </div>
                        <div className="text-right text-headings print-bold">
                            {item.transactionMode}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 font-medium mx-4 border-b pb-3 border-black">
                        <div className='-ml-4 print-bold'>
                            Currency:
                        </div>
                        <div className="text-right text-headings print-bold">
                            {item.currency}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 font-medium mx-4 border-b pb-3 border-black">
                        <div className='-ml-4 print-bold'>
                            Amount:
                        </div>
                        <div className="text-right text-headings print-bold">
                            {item.amount}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 font-medium mx-4 border-b pb-3 border-black">
                        <div className='-ml-4 print-bold'>
                            Rate:
                        </div>
                        <div className="text-right text-headings print-bold">
                            {item.rate}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 font-medium mx-4 border-b pb-3 border-black">
                        <div className='-ml-4 print-bold'>
                            Commission:
                        </div>
                        <div className="text-right text-headings print-bold">
                            {item.commissionType === 'per' ? `${item.commission}%` : `AUD ${item.commission}`}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 font-medium mx-4 border-b pb-3 border-black">
                        <div className='-ml-4 print-bold'>
                            Cash Handling:
                        </div>
                        <div className="text-right text-headings print-bold">
                            {item.cashHandling}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 font-bold text-lg mx-4 border-b pb-3 border-black">
                        <div className='-ml-4'>
                            Total:
                        </div>
                        <div className="text-right text-headings">
                            {item.payableAmount}
                        </div>
                    </div>
                </div>
            ))}
            {transactions.length > 0 ? (<div className="grid grid-cols-2 font-bold text-lg mx-4">
                <div className='-ml-4'>
                    Grand Total:
                </div>
                <div className="text-right text-headings">
                    {totalPayable}
                </div>
            </div>) : ''}
            <div className='text-center print-bold'>Have a nice day.</div>
            {transactions.length > 0 ? (<div className='flex'>
                <Button type='button' className='bg-red-600 text-white px-5 mr-4 save-btn' onClick={handleSubmit}>SAVE</Button>
                <Button type='button' className='bg-red-600 text-white px-5 save-btn' onClick={handleSaveAndPrint}>SAVE & PRINT</Button>
            </div>) : ''}
        </div>
    )
});

const FormTemplate = ({
    handleSubmit,
    rates,
    selectedCurrency,
    setSelectedCurrency,
    currencyName,
    setCurrencyName,
    currencyAmount,
    setCurrencyAmount,
    convertedAmount,
    setConvertedAmount,
    commission,
    setCommission,
    commissionType,
    setCommissionType,
    commissionVal,
    setCommissionVal,
    cashHandling,
    setCashHandling,
    cashHandlingType,
    setCashHandlingType,
    cashHandlingVal,
    setCashHandlingVal,
    setPaymentAmount,
    setPaymentMethod,
    handleTransactions,
    totalConverted,
    totalReceived,
    totalCommission,
    totalAmount,
    totalPayable,
    showPayment,
    transactions,
    reverse,
    setReverse,
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [userModal, setUserModal] = useState(false);
    const [idType, setIdType] = useState('');
    const [idIssuer, setIdIssuer] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [idIssueDate, setIdIssueDate] = useState('');
    const [idExpiryDate, setIdExpiryDate] = useState('');
    const [dob, setDob] = useState('');
    const [address, setAddress] = useState('');
    const [occupation, setOccupation] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [shouldPrint, setShouldPrint] = useState(false);

    const componentRef = useRef();
    const printButtonRef = useRef();

    useEffect(() => {
        if (shouldPrint) {
            printButtonRef.current.click();
            setShouldPrint(false);
        }
    }, [shouldPrint]);

    const handleSaveAndPrint = (e) => {
        if (e) e.preventDefault();
        handleSubmit(e); 
        setShouldPrint(true);
    };

    const handleCustomerInfo = async (e) => {
        e.preventDefault();
        if (idType && idIssuer && idNumber && customerName && customerEmail && idIssueDate && idExpiryDate && dob && address && occupation && phoneNumber) {
            try {
                const saveData = await axios.post(`${process.env.REACT_APP_API_URL}saveCustomerData`, {
                    idType, idIssuer, idNumber, customerName, customerEmail, idIssueDate, idExpiryDate, dob, address, occupation, phoneNumber
                });
                if (saveData.status === 200) {
                    setIdType('');
                    setIdIssuer('');
                    setIdNumber('');
                    setCustomerName('');
                    setCustomerEmail('');
                    setIdIssueDate('');
                    setIdExpiryDate('');
                    setDob('');
                    setAddress('');
                    setOccupation('');
                    setPhoneNumber('');
                    setUserModal(false);
                    setOpenModal(true);
                    toast.success('Success: Customer Information Saved');
                }
            } catch (error) {
                toast.error('Error: Data not saved.');
            }
        } else {
            toast.error('Please fill all fields.');
        }
    }

    const currencyOptions = Object.values(rates).map((value) => (
        <option className='text-xl' key={value._id} value={showPayment ? value.sellRate : value.buyRate}>{value.currency}</option>
    ));

    const handleReverse = () => {
        const convertedAmountInAUD = commissionType === 'per' ? (showPayment ? parseFloat(reverse) / ((100 + parseFloat(commissionVal)) / 100) : parseFloat(reverse) / ((100 - parseFloat(commissionVal)) / 100)) : (showPayment ? (parseFloat(reverse) + parseFloat(commissionVal)) : (parseFloat(reverse) - parseFloat(commissionVal)));
        const convertedCurrency = showPayment ? roundValue(convertedAmountInAUD * selectedCurrency) : roundValue(convertedAmountInAUD * selectedCurrency);
        setCurrencyAmount(convertedCurrency);
    }

    const handleSave = (payment) => {
        if (transactions.length < 1) {
            const checkTransaction = handleTransactions();
            if (checkTransaction) {
                const paymentAmount = (payment && !reverse) || (!payment && reverse) ? (roundValue(convertedAmount) + roundValue(commission) + roundValue(cashHandling)).toFixed(2) : (roundValue(convertedAmount) - roundValue(commission) - roundValue(cashHandling)).toFixed(2);
                paymentAmount > 1000 ? setUserModal(true) : setOpenModal(true)
            }
        } else {
            totalPayable > 1000 ? setUserModal(true) : setOpenModal(true);
        }
    }
    useEffect(() => {
        const convertedAmount = () => {
            (selectedCurrency !== 0) && setConvertedAmount(currencyAmount * (1 / selectedCurrency));
        };
        convertedAmount();
    }, [currencyAmount, selectedCurrency, setConvertedAmount])

    useEffect(() => {
        const setCommissions = () => {
            setCommission(commissionVal ? (commissionType === 'per' ? (parseFloat(convertedAmount) * (parseFloat(commissionVal) / 100)) : commissionVal) : 0);
            setCashHandling(cashHandlingVal ? (cashHandlingType === 'per' ? (parseFloat(convertedAmount) * (parseFloat(cashHandlingVal) / 100)) : cashHandlingVal) : 0);
        };
        setCommissions();
    }, [commissionType, commissionVal, cashHandlingType, cashHandlingVal, convertedAmount, setCommission, setCashHandling])

    return (
        <>
            <form className="flex flex-col gap-4">
                <div className="grid grid-cols-3">
                    <div className="col-span-2 flex items-center">
                        <div className="p-2 w-1/4">
                            <Label htmlFor="currency" value="Select Currency" />
                        </div>
                        <div className='w-1/2'>
                            <Select id="currency" sizing='lg' defaultValue={'DEFAULT'} onChange={(event) => { setSelectedCurrency(event.target.value); setCurrencyName(event.target.options[event.target.selectedIndex].innerText); }} required>
                                <option className='text-xl' value="DEFAULT" disabled>Select</option>
                                {currencyOptions}
                            </Select>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="p-2">
                            <Label htmlFor="rate" value="Rate" />
                        </div>
                        <div className='p-2 w-1/2'>
                            <div id="rate" className='bg-gradient-to-br from-theme-primary to-theme-secondary rounded-lg text-xl text-white p-3 w-24 text-center'>{parseFloat(selectedCurrency).toFixed(4)}</div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3">
                    <div className="col-span-2 flex items-center">
                        <div className="p-2 w-1/4">
                            <Label htmlFor="amount" value="Amount" />
                        </div>
                        <div className='w-1/2'>
                            <TextInput id="amount" onChange={(event) => setCurrencyAmount(event.target.value)} value={currencyAmount} type="number" sizing="lg" />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3">
                    <div className="col-span-3 flex">
                        <div className="p-2 w-1/5">
                            <Label htmlFor="commission" value="Commission Activity" />
                        </div>
                        <div className='w-4/5 pl-5 xl:pl-0'>
                            <div className="w-full xl:flex items-center">
                                <div className="xl:w-2/3 pb-3 xl:pb-0">
                                    <Radio id="per1" name="commission" value="per" className='bg-gray-400 mr-2' onClick={() => setCommissionType("per")} checked={commissionType === "per"} />
                                    <Label htmlFor="per1" className='mr-8'>Per %</Label>
                                    <Radio id="fixed1" name="commission" value="fixed" className='bg-gray-400 mr-2' onClick={() => setCommissionType("fixed")} checked={commissionType === "fixed"} />
                                    <Label htmlFor="fixed1" className='mr-10'>Fixed</Label>
                                </div>
                                <div className="xl:w-1/2">
                                    <Label htmlFor="value1" className='mr-5'>Value</Label>
                                    <input type="number" name="value1" id="value1" onChange={(event) => setCommissionVal(event.target.value)} value={commissionVal} className='bg-gradient-to-br from-theme-primary to-theme-secondary rounded-lg text-md text-white' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3">
                    <div className="col-span-3 flex">
                        <div className="p-2 w-1/5">
                            <Label htmlFor="commission" value="Cash Handling" />
                        </div>
                        <div className='w-4/5'>
                            <div className="w-full flex items-center">
                                <Label htmlFor="value2" className='mr-5'>Value</Label>
                                <input type="number" name="cashHandling" id="cashHandling" value={cashHandling} onChange={(event) => setCashHandlingVal(event.target.value)} className='bg-gradient-to-br from-theme-primary to-theme-secondary rounded-lg text-md text-white' />
                            </div>
                        </div>
                    </div>
                </div>
                <PaymentInfo showPayment={showPayment} setPaymentAmount={setPaymentAmount} setPaymentMethod={setPaymentMethod} />
                <div className="grid grid-cols-3">
                    <div className="col-span-3 flex items-center">
                        <div className="p-2 w-1/4">
                            <Label htmlFor="reverse" value="Reverse" />
                        </div>
                        <div className='w-1/2'>
                            <input id="reverse" className='bg-gradient-to-br from-theme-primary to-theme-secondary rounded-lg text-md text-white' onChange={(event) => { setReverse(event.target.value) }} type="number" />
                        </div>
                        <Button type='button' className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-0' onClick={handleReverse}>Reverse</Button>
                    </div>
                </div>
                <div className='flex gap-4 mx-2 my-5'>
                    <Button type='button' className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5' onClick={handleTransactions}>Add Transaction</Button>
                    <Button type='button' onClick={() => handleSave(showPayment)} className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5'>Save and Settle</Button>
                </div>
            </form>
            <Modal dismissible size='md' show={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Body className='bg-soft-gray border-dashed border-2 border-black'>
                    <div className="space-y-6">
                        <Invoice ref={componentRef} transactions={transactions} totalPayable={totalPayable} handleSaveAndPrint={handleSaveAndPrint} handleSubmit={handleSubmit} setOpenModal={setOpenModal} />

                        <ReactToPrint

                            trigger={() => <Button ref={printButtonRef} type='button' className='bg-red-600 text-white px-5 mx-auto hidden'>Print</Button>}

                            content={() => componentRef.current}

                            pageStyle={`

                                @page {

                                    size: 480mm auto;

                                    margin: 5mm;

                                }

                                @media print {

                                    body {

                                        font-family: 'Courier New', Courier, monospace;

                                        font-size: 12px;

                                    }

                                    .invoice-to-print {
                                        width: 80mm;
                                    }

                                    .invoice-to-print > * + * {
                                        margin-top: 0 !important;
                                    }

                                    .invoice {

                                        display: block !important;

                                    }

                                    .save-btn, .cross-icon {
                                        display: none !important;
                                    }

                                    .print-bold {
                                        font-weight: bold;
                                    }

                                }

                                .invoice {

                                    display: none;

                                }

                                h1 {

                                    text-align: center;

                                    font-size: 24px;

                                    font-weight: bold;

                                }

                                table {

                                    width: 100%;

                                    border-collapse: collapse;

                                }

                                th, td {

                                    padding: 0;

                                    padding-bottom: 0 !important;

                                }

                                hr {

                                    border: none;

                                    border-top: 1px solid #000;

                                    margin: 5px 0;

                                }

                            `}

                        />
                        {/* <Button type='button' className='bg-red-600 text-white px-5 mx-auto' onClick={handleSubmit}>Print</Button> */}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal dismissible size='md' show={userModal} onClose={() => setUserModal(false)}>
                <Modal.Body className='bg-soft-gray border-dashed border-2 border-black'>
                    <div className="space-y-6">
                        <ImCross className='ml-auto' onClick={() => { setUserModal(false); setOpenModal(true) }} />
                        <h2 className='text-center font-bold text-xl'>Save Customer Info</h2>
                        <form className="flex max-w-md flex-col gap-4">
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="idType" value="Id Type" />
                                </div>
                                <TextInput id="idType" type="text" placeholder="id type" onChange={(event) => { setIdType(event.target.value) }} required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="idIssuer" value="Id Issuer" />
                                </div>
                                <TextInput id="idIssuer" type="text" placeholder="id issuer" onChange={(event) => { setIdIssuer(event.target.value) }} required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="idNumber" value="Id Number" />
                                </div>
                                <TextInput id="idNumber" type="number" placeholder="id Number" onChange={(event) => { setIdNumber(event.target.value) }} required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="customerName" value="Name" />
                                </div>
                                <TextInput id="customerName" placeholder='customer name' type="text" onChange={(event) => { setCustomerName(event.target.value) }} required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="email" value="Email" />
                                </div>
                                <TextInput id="email" type="email" placeholder="name@email.com" onChange={(event) => { setCustomerEmail(event.target.value) }} required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="idIssueDate" value="Id Issue Date" />
                                </div>
                                <TextInput id="IdIssueDate" type="date" placeholder="id issue date" onChange={(event) => { setIdIssueDate(event.target.value) }} required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="idExpiryDate" value="Id Expiry Date" />
                                </div>
                                <TextInput id="idExpiryDate" type="date" placeholder="id expiry date" onChange={(event) => { setIdExpiryDate(event.target.value) }} required />
                            </div>

                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="dob" value="Date of Birth" />
                                </div>
                                <TextInput id="dob" type="date" placeholder="date of birth" onChange={(event) => { setDob(event.target.value) }} required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="address" value="Address" />
                                </div>
                                <TextInput id="address" type="text" placeholder="address" onChange={(event) => { setAddress(event.target.value) }} required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="occupation" value="Occupation" />
                                </div>
                                <TextInput id="occupation" type="text" placeholder="occupation" onChange={(event) => { setOccupation(event.target.value) }} required />
                            </div>
                            <div>
                                <div className="mb-2 block">
                                    <Label htmlFor="phoneNumber" value="Phone Number" />
                                </div>
                                <TextInput id="phoneNumber" type="tel" placeholder="phone number" onChange={(event) => { setPhoneNumber(event.target.value) }} required />
                            </div>

                            <button type="submit" className='mt-5 py-2 text-white rounded-lg bg-gradient-to-br from-theme-primary to-theme-secondary' onClick={(e) => { handleCustomerInfo(e) }}>Save Info</button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FormTemplate;