import React, { useState, useContext } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
// import { UserContext } from '../context';
// import Validate from '../shared/validation/Validate';

function Settings() {
  // const [state, setState] = useContext(UserContext);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", file);

    try {
      // Simulate upload progress
      const uploadInterval = setInterval(() => {
        setUploadProgress((prevProgress) => Math.min(prevProgress + 10, 100));
      }, 500);

      const response = await axios.post(process.env.REACT_APP_API_URL + 'uploadCurrencyRates', formData, {
        onUploadProgress: (progressEvent) => {
          setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        },
      });

      clearInterval(uploadInterval);

      const data = await response.data;
      console.log(data);
      if (data.success) {
        setUploadProgress(0);
        setErrorMessage('');
        toast.success('Currency Rates Updated Successfully.');
        // Handle success (e.g., display success message, redirect)
      } else {
        setErrorMessage(data.error);
        toast.error('Error: Currency Rates not Updated.');

      }
    } catch (error) {
      setUploadProgress(0);
      setErrorMessage('An error occurred during upload.');
      toast.error('Error: Currency Rates not Updated.');
    }
  };

  return (
    <div className="">
      <div className="">
        <h1 className="text-headings text-3xl font-medium mt-10">Upload Currency Rates</h1>
        <div className='mt-12'>
          <form onSubmit={handleSubmit}>
            <input type="file" accept=".xlsx,.xlsm" onChange={handleFileChange} />
            <button type="submit" className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5 py-2 rounded-md' disabled={uploadProgress > 0 || !file}>
              {uploadProgress > 0 ? `Uploading...` : 'Upload'}
            </button>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
        </div>
      </div>
    </div>
    // <Validate>
    // <form onSubmit={handleSubmit}>
    //   <input type="file" accept=".xlsx,.xlsm" onChange={handleFileChange} />
    //   <button type="submit" disabled={uploadProgress > 0}>
    //     {uploadProgress > 0 ? `Uploading... ${uploadProgress}%` : 'Upload Excel File'}
    //   </button>
    //   {errorMessage && <p className="error-message">{errorMessage}</p>}
    // </form>
    // </Validate>
  );
}

export default Settings;