import React, { useState, useEffect } from "react";
import { Table } from "flowbite-react";
import { FaArrowRightLong } from "react-icons/fa6";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const LocalBalance = () => {

    // getting parameters for balance type from url
    const { balanceType } = useParams();
    // states to store denominations in array to loop over them and for grand total of balance
    const [rows, setRows] = useState([]);
    const [bulkMultipliers, setBulkMultipliers] = useState({});
    const [grandTotal, setGrandTotal] = useState(0);


    // useEffect(() => {
    //     // Calculate multipliers once on initial render
    //     const multipliers = new Map();
    //     multipliers.set(2, 25);
    //     multipliers.set(1, 20);
    //     multipliers.set(0.5, 20);
    //     multipliers.set(0.2, 20);
    //     multipliers.set(0.1, 40);
    //     multipliers.set(0.05, 40);
    //     setBulkMultipliers(multipliers);
    // }, []);

    // function to get balance from given parameter
    const getBalance = async () => {
        const balance = await axios.get(process.env.REACT_APP_API_URL + 'getLocalCurrencyBalance', {
            params: { balanceType: balanceType.replace(/-/g, '') }
        });
        if (balance.data.length) {
            setRows(balance.data);
        }
    }
    useEffect(() => {
        getBalance();
    });

    // Calculate grand total on initial render and update state
    useEffect(() => {
        const initialTotal = rows.reduce((acc, row) => acc + row.total, 0);
        setGrandTotal(initialTotal);
        // console.log(rows);
    }, [rows]);

    const handleInputChange = (index, inputIndex, value) => {
        // setRows(prevState => {
        //     const updatedRows = [...prevState];
        //     updatedRows[index] = { ...prevState[index], [inputIndex]: value };
        //     console.log(updatedRows);
        //     return updatedRows;
        //   });
        // setRows((prevRows) =>
        //     prevRows.map((row, rowId) => {
        //         if (rowId === index) {
        //             const updatedRow = { ...row, [`${inputIndex}`]: parseInt(value) };
        //             // // Access multiplier efficiently from bulkMultipliers
        //             // const bulkMultiplier = bulkMultipliers.get(updatedRow.denomination) || 10;
        //             // // Calculate total
        //             // updatedRow.total =
        //             //     updatedRow.looseQuantity * updatedRow.denomination +
        //             //     updatedRow.bulkQuantity * bulkMultiplier * updatedRow.denomination;

        //             // // Re-calculate grand total based on the updated row
        //             // const newGrandTotal = prevRows
        //             //     .map((row) => row === updatedRow ? updatedRow.total : row.total)
        //             //     .reduce((acc, row) => acc + row, 0);

        //             // setGrandTotal(newGrandTotal);

        //             return updatedRow;
        //         } else {
        //             return row;
        //         }
        //     })
        // );
    };
    // useEffect(() => {
    //     console.log(rows); // Will log the updated rows after the state change
    //   }, [rows]);

    const handleUpdate = async (req, res) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/updateLocalCurrencyBalance`, rows);
            toast.success('Success: Balance updated.');
        } catch (error) {
            toast.error('Error: Balance not updated.');
        }
    };

    return (
        <div className="">
            <div className="">
                <h1 className="text-headings text-3xl font-medium mt-10 capitalize">Local Currency Balance -  {balanceType.replace(/-/g, ' ')}</h1>
            </div>
            <div className="overflow-x-auto mt-12">
                <div className="flex justify-end px-5 pb-5">
                    <span className="text-lg align-middle font-medium mr-5">Total:</span>
                    <div className="bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-4 py-3 text-lg">{grandTotal.toFixed(2)}/-</div>
                </div>
                <Table>
                    <Table.Head>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Sr #</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Balance Inquiry</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Loose Quantity</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Bulk Quantity</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Denomination</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Total</Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                        {rows.map((row, index) => (
                            <Table.Row key={index}>
                                <Table.Cell>
                                    {index + 1}
                                </Table.Cell>
                                <Table.Cell className="flex">
                                    AUD <FaArrowRightLong className="mx-1 mt-1" /> Australian Dollar
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        type="number"
                                        onBlur={(e) => handleInputChange(index, 'looseQuantity', e.target.value)}
                                        defaultValue={row.looseQuantity}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        type="number"
                                        defaultValue={row.bulkQuantity}
                                        onBlur={(e) => handleInputChange(index, 'bulkQuantity', e.target.value)}
                                    />
                                </Table.Cell>
                                <Table.Cell>{row.denomination < 1 ? row.denomination * 100 : row.denomination} {row.denomination < 1 ? 'c' : '$'}</Table.Cell>
                                <Table.Cell>{row.total.toFixed(2)}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <button className="bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-4 py-3 text-lg mt-4" onClick={handleUpdate}>Update Balance</button>
            </div>
        </div>
    )
}

export default LocalBalance;