import React, { useState, useEffect } from "react";
import { Table } from "flowbite-react";
import { FaArrowRightLong } from "react-icons/fa6";
import axios from "axios";
import { toast } from "react-toastify";

const Usd = () => {
    const [rows, setRows] = useState([
        { currency: 'usd', looseQuantity: 0, bulkQuantity: 0, denomination: 100, total: 0 },
        { currency: 'usd', looseQuantity: 0, bulkQuantity: 0, denomination: 50, total: 0 },
        { currency: 'usd', looseQuantity: 0, bulkQuantity: 0, denomination: 20, total: 0 },
        { currency: 'usd', looseQuantity: 0, bulkQuantity: 0, denomination: 10, total: 0 },
        { currency: 'usd', looseQuantity: 0, bulkQuantity: 0, denomination: 5, total: 0 },
        { currency: 'usd', looseQuantity: 0, bulkQuantity: 0, denomination: 2, total: 0 },
        { currency: 'usd', looseQuantity: 0, bulkQuantity: 0, denomination: 1, total: 0 },
    ]);
    const [grandTotal, setGrandTotal] = useState(0);

    const getBalance = async () => {
        const balance = await axios.get(process.env.REACT_APP_API_URL + 'getForeignCurrencyBalance', {
            params: { currency: 'usd' }
        });
        if (balance.data.length) {
            setRows(balance.data);
        }
    }
    useEffect(() => {
        getBalance();
    }, []);

     // Calculate grand total on initial render and update state
     useEffect(() => {
        const initialTotal = rows.reduce((acc, row) => acc + row.total, 0);
        setGrandTotal(initialTotal);
    }, [rows]);

    const handleInputChange = (index, inputIndex, value) => {
        setRows((prevRows) =>
            prevRows.map((row, rowId) => {
                if (rowId === index) {
                    const updatedRow = { ...row, [`${inputIndex}`]: value };

                    // Calculate total
                    updatedRow.total =
                        updatedRow.looseQuantity * updatedRow.denomination +
                        updatedRow.bulkQuantity * 10 * updatedRow.denomination;

                    // Re-calculate grand total based on the updated row
                    const newGrandTotal = prevRows
                        .map((row) => row === updatedRow ? updatedRow.total : row.total)
                        .reduce((acc, row) => acc + row, 0);

                    setGrandTotal(newGrandTotal);

                    return updatedRow;
                } else {
                    return row;
                }
            })
        );
    };

    const handleUpdate = async (req, res) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/updateForeignCurrencyBalance`, rows);
            toast.success('Success: Balance updated.');
        } catch (error) {
            toast.error('Error: Balance not updated.');
        }
    };

    return (
        <div className="">
            <div className="">
                <h1 className="text-headings text-3xl font-medium mt-10 flex">Foreign Currency Balance - USD <FaArrowRightLong className="mx-3 mt-1" /> United States</h1>
            </div>
            <div className="overflow-x-auto mt-12">
                <div className="flex justify-end px-5 pb-5">
                    <span className="text-lg align-middle font-medium mr-5">Total:</span>
                    <div className="bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-4 py-3 text-lg">{grandTotal}/-</div>
                </div>
                <Table>
                    <Table.Head>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Sr #</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Balance Inquiry</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Loose Quantity</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Bulk Quantity</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Denomination</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Total</Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                        {rows.map((row, index) => (
                            <Table.Row key={index}>
                                <Table.Cell>
                                    {index + 1}
                                </Table.Cell>
                                <Table.Cell className="flex">
                                    USD <FaArrowRightLong className="mx-1 mt-1" /> United States
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        type="number"
                                        onChange={(e) => handleInputChange(index, 'looseQuantity', e.target.value)}
                                        value={row.looseQuantity}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        type="number"
                                        value={row.bulkQuantity}
                                        onChange={(e) => handleInputChange(index, 'bulkQuantity', e.target.value)}
                                    />
                                </Table.Cell>
                                <Table.Cell>{row.denomination}</Table.Cell>
                                <Table.Cell>{row.total}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
                <button className="bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-4 py-3 text-lg mt-4" onClick={handleUpdate}>Update Balance</button>
            </div>
        </div>
    )
}

export default Usd;