import React from 'react';
import { useState, useContext } from 'react';
import { UserContext } from '../context';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Label, TextInput } from 'flowbite-react';

const Login = () => {
    const [state, setState] = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}/login`,
                {
                    email, password
                });
            setEmail('');
            setPassword('');
            // update context
            setState({
                user: data.user,
                token: data.token,
            });
            // save in local storage
            window.localStorage.setItem("auth", JSON.stringify(data));
        } catch (error) {
            toast.error(error.response.data);
        }
    }

    return (
        <form className="flex max-w-md flex-col gap-4 mx-auto mt-24" onSubmit={handleSubmit}>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="email" value="Email" />
                </div>
                <TextInput id="email" type="email" placeholder="name@email.com" value={email} onChange={e => setEmail(e.target.value)} required shadow />
            </div>
            <div>
                <div className="mb-2 block">
                    <Label htmlFor="password" value="Password" />
                </div>
                <TextInput id="password" type="password" value={password} onChange={e => setPassword(e.target.value)} required shadow />
            </div>
            <button disabled={!email || !password} className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white py-2 rounded-lg'>Login</button>
        </form>
    );
}

export default Login;