
'use client';

import { Table } from 'flowbite-react';
import { AiOutlineCloseCircle } from "react-icons/ai";

const TableTemplate = ({transactions, handleRemoveTransaction, totalConverted, totalCommission, totalCashHandling, totalPayable, totalReceived}) => {

  const tableRows = transactions.map((item, index) => (
    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
        {index+1}
      </Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.currency}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.amount}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.rate}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white bg-theme-secondary/50">{item.converted}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.per}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.fixed}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white bg-theme-secondary/50 border-r-2 border-white">{item.commAmount}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white bg-theme-secondary/50">{item.cashHandling}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.particular}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.surPer}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.surFixed}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.surcharges}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white bg-theme-secondary/50">{item.payableAmount}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.cashParticular}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white">{item.paidAmount}</Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white bg-theme-secondary/50 border-r border-white"></Table.Cell>
      <Table.Cell className="font-medium text-gray-900 dark:text-white bg-theme-secondary/50"><AiOutlineCloseCircle  className='mx-auto text-white text-3xl' onClick={() => handleRemoveTransaction(index) }/></Table.Cell>
    </Table.Row>
  ));
  return (
    <div className="overflow-x-auto">
      <Table className='text-center table-auto w-full'>
        <Table.Head>
          <Table.HeadCell className='bg-theme-secondary text-white border-r-2 border-white' colSpan={5}>Curerncy Amount</Table.HeadCell>
          <Table.HeadCell className='bg-theme-secondary text-white border-r-2 border-white' colSpan={3}>Commission Activity Area</Table.HeadCell>
          <Table.HeadCell className='bg-theme-secondary text-white border-r-2 border-white'>Cash Handling Area</Table.HeadCell>
          <Table.HeadCell className='bg-theme-secondary text-white border-r-2 border-white' colSpan={4}>Surcharges Activity Area</Table.HeadCell>
          <Table.HeadCell className='bg-theme-secondary text-white' colSpan={5}>Cash Activity Area</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>#</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>Currency</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>Amount</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>Rate</Table.Cell>
            <Table.Cell className='bg-theme-secondary/70 text-white border-r-2 border-white'>Converted</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>Per%</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>Fixed</Table.Cell>
            <Table.Cell className='bg-theme-secondary/70 text-white border-r-2 border-white'>Comm Amount</Table.Cell>
            <Table.Cell className='bg-theme-secondary/70 text-white border-r-2 border-white'>Cash Handling</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>Particular</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>Per%</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>Fixed</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r-2 border-white'>Surcharges</Table.Cell>
            <Table.Cell className='bg-theme-secondary/70 text-white border-r border-white'>Amount</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>Particular</Table.Cell>
            <Table.Cell className='bg-zinc-600 text-white border-r border-white'>Amount</Table.Cell>
            <Table.Cell className='bg-theme-secondary/70 text-white border-r border-white'>Received</Table.Cell>
            <Table.Cell className='bg-theme-secondary/70 text-white'>Action</Table.Cell>
          </Table.Row>
          {tableRows}
          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap text-lg font-bold text-headings">
              Total
            </Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium bg-theme-secondary text-white">AUD {totalConverted}</Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium bg-theme-secondary text-white border-r-2 border-white">AUD {totalCommission}</Table.Cell>
            <Table.Cell className="font-medium bg-theme-secondary text-white">AUD {totalCashHandling}</Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium bg-theme-secondary text-white">AUD {totalPayable}</Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium text-gray-900 dark:text-white"></Table.Cell>
            <Table.Cell className="font-medium bg-theme-secondary text-white border-r border-white"></Table.Cell>
            <Table.Cell className="font-medium bg-theme-secondary text-white"></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}

export default TableTemplate;