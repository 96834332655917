import React from "react";
import BuySell from "../shared/BuySell";

const SellingCurrency = () => {
    return (
        <div className="">
            <div className="">
                <h1 className="text-headings text-3xl font-medium mt-10">Selling Currency</h1>
                <BuySell payment={true}/>
            </div>
        </div>
    )
}

export default SellingCurrency;