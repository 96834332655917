"use client";
import { Avatar, Sidebar } from 'flowbite-react';
import { FaPlay } from "react-icons/fa6";
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

const SidebarItem = ({ path, text}) => {
  const resolvedPath = useResolvedPath(path);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true});
  const className = `flex items-center text-white hover:bg-white/10 pl-3 w-full ${ isActive ? 'active' : ''}`;
  const icon = isActive ? <FaPlay className='text-white h-3'/> : '';
  return (
    <Link to={path} className={className}>
      <span className='w-2'>{icon}</span>
      <span className='ml-3'>{text}</span>
      {/* <Sidebar.Item className={className}>
        {text}
      </Sidebar.Item> */}
    </Link>
  )
}

const SidebarTemplate = () => {
  return (
    <>
      <Sidebar aria-label="Sidebar with multi-level dropdown example" className='h-screen'>
        <Avatar rounded size="lg" className='my-5 space-x-5'>
          <div className="space-y-1 font-medium dark:text-white">
            <div>Welcome</div>
            <div className="text-sm text-gray-500 dark:text-gray-400">Elena Smith</div>
          </div>
        </Avatar>
        <Sidebar.Items className='h-[calc(100%-16rem)] py-10 rounded-lg bg-gradient-to-bl from-theme-primary to-theme-secondary' theme={{
          icon: {
            base: 'text-white'
          }
        }}>
          <Sidebar.ItemGroup>
            <SidebarItem path='/selling-currency' text='Selling Currency'></SidebarItem>
            <SidebarItem path='/buying-currency' text='Buying Currency'></SidebarItem>
            <SidebarItem path='/local-currency-balance' text='Local Currency Balance'></SidebarItem>
            <SidebarItem path='/foreign-currency-balance' text='Foreign Currency Balance'></SidebarItem>
            <SidebarItem path='/reports' text='Reports'></SidebarItem>
            <SidebarItem path='/settings' text='Settings'></SidebarItem>
            {/* <Link to="/" className='hidden'>
              <Sidebar.Item href="#" icon={FaPlay} className='text-white'>
                Local Currency Balance
              </Sidebar.Item>
            </Link>
            <Link to="/" className='hidden'>
              <Sidebar.Item href="#" icon={FaPlay} className='text-white'>
                Foreign Currency Balance
              </Sidebar.Item>
            </Link>
            <Sidebar.Collapse icon={FaPlay} label="Report" className='text-white hidden'>
              <Link to="/"><Sidebar.Item href="#" className='text-white'>Buy Report</Sidebar.Item></Link>
              <Link to="/"><Sidebar.Item href="#" className='text-white'>Sell Report</Sidebar.Item></Link>
            </Sidebar.Collapse> */}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </>
  );
}

export default SidebarTemplate;