import React from "react";
import { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from "../context";
import axios from 'axios';
import { Button } from 'flowbite-react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { CsvExportModule } from 'ag-grid-community';


const Reports = () => {
    const [state, setState] = useContext(UserContext);
    const [reports, setReports] = useState([]);

    const transactions = async () => {
        const allReports = await axios.get(process.env.REACT_APP_API_URL + 'getAllReports');
        setReports(allReports.data);
    }
    useEffect(() => {
        transactions();
    }, []);

    const colDefs = [
        {
            colId: 'index',
            headerName: '#',
            valueGetter: (params) => params.node.rowIndex + 1,
            width: 70,
        },
        // { field: "_id", headerName: 'Trans#', },
        { field: "user_name", headerName: 'User' },
        { field: "date", headerName: 'Date', filter: true, },
        { field: "time", headerName: 'Time', },
        { field: "transactionMode", headerName: 'Type', filter: true, },
        { field: "currency", headerName: 'CRY', filter: true, },
        { field: "exchangeValue", headerName: 'Rate/AUD', },
        { field: "currencyAmount", headerName: 'Amount' },
        { field: '', valueFormatter: (row) => (`${row.data.commissionValue}${row.data.commissionType === 'per' ? '%' : ' AUD'}`), headerName: 'Commission' },
        { field: 'cashHandlingValue', headerName: 'Cash Handling', },
        { field: 'paymentAmount', headerName: 'Cash' },
    ];

    const gridApi = useRef(null);

    const handleExport = () => {
        if (gridApi.current) {
            gridApi.current.api.exportDataAsCsv();
        }
    }

    const onGridReady = (params) => {
        gridApi.current = params.api;
        params.api.sizeColumnsToFit();
    }

    const onResize = () => {
        if (gridApi.current) {
            gridApi.current.sizeColumnsToFit();
        }
    }

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const getRowStyle = params => {
        return {
            backgroundColor: params.data.transactionMode === "Sell" ? 'rgba(255,255,0,0.4)' : 'rgba(0,255,255,0.2)',
            width: '100%',
        }
    }

    // const gridOptions = {
    //     rowData: reports,
    //     columnDefs: colDefs,
    //     pagination: true,
    //     getRowStyle: getRowStyle,
    //     modules: [CsvExportModule],
    // }

    return (
        <div className="">
            <div className="">
                <h1 className="text-headings text-3xl font-medium mt-10">Reports</h1>
                <div>
                    <Button type='button' className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5 ml-auto mr-5 mt-6 mb-6' onClick={handleExport}>Export</Button>
                    <div className="ag-theme-quartz" style={{ height: 600, width: '100%', }}>
                        {/* <AgGridReact {...gridOptions} ref={gridApi} /> */}
                        <AgGridReact
                            onGridReady={onGridReady}
                            rowData={reports}
                            columnDefs={colDefs}
                            getRowStyle={getRowStyle}
                            pagination={true}
                            modules={[CsvExportModule]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports;