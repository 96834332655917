import { UserProvider } from './context';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SellingCurrency from './pages/SellingCurrency';
import BuyingCurrency from './pages/BuyingCurrency';
import SidebarTemplate from './shared/SidebarTemplate';
import { Route, Routes } from 'react-router-dom';
import Reports from './pages/Reports';
import Register from './pages/Register';
import Login from './pages/Login';
import LocalCurrencyBalance from './pages/LocalCurrencyBalance';
import BulkCoins from "./shared/localCurrencyBalance/BulkCoins";
import Safe from './shared/localCurrencyBalance/Safe';
import Notes from './shared/localCurrencyBalance/Notes';
import CoinsInTray from './shared/localCurrencyBalance/CoinsInTray';
import ForeignCurrencyBalance from './pages/ForeignCurrencyBalance';
import Usd from './shared/foreignCurrencyBalance/Usd';
import Gbp from './shared/foreignCurrencyBalance/Gbp';
import Eur from './shared/foreignCurrencyBalance/Eur';
import LocalBalance from './shared/localCurrencyBalance/LocalBalance';
import Settings from './pages/Settings';

function App() {
  return (
    <UserProvider>
      <div className="flex">
        <ToastContainer position='top-right' theme="colored" />
        <SidebarTemplate className="w-64" />
        <div className="p-4 w-[calc(100%-16rem)]">
          <Routes>
            <Route path="/selling-currency" element={<SellingCurrency />} />
            <Route path="/buying-currency" element={<BuyingCurrency />} />
            <Route path="/local-currency-balance" element={<LocalCurrencyBalance />} />
            <Route path="/local-currency-balance/:balanceType" element={<LocalBalance />} />
            <Route path="/safe" element={<Safe />} />
            <Route path="/bulk-coins" element={<BulkCoins />} />
            <Route path="/notes" element={<Notes />} />
            <Route path="/coins-in-tray" element={<CoinsInTray />} />
            <Route path="/foreign-currency-balance" element={<ForeignCurrencyBalance />} />
            <Route path="/foreign-currency-balance/usd" element={<Usd />} />
            <Route path="/foreign-currency-balance/gbp" element={<Gbp />} />
            <Route path="/foreign-currency-balance/eur" element={<Eur />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </UserProvider>
  );
}

export default App;
