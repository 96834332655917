import React from "react";
import { Table } from "flowbite-react";
import { useNavigate } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";

const ForeignCurrencyBalance = () => {
    const navigate = useNavigate();
    return (
        <div className="">
            <div className="">
                <h1 className="text-headings text-3xl font-medium mt-10">Foreign Currency Balance</h1>
            </div>
            <div className="overflow-x-auto mt-12">
                <Table>
                    <Table.Head>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Currency</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Description</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Action</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        <Table.Row className="bg-slate-200 dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-bold text-lg text-theme-primary flex">USD <FaArrowRightLong className="mx-1 mt-1" /> United States</Table.Cell>
                            <Table.Cell>Lorem ipsum dolor sit amet sonsecterue. Lorem ipsum dolor sit amet consectetur.</Table.Cell>
                            <Table.Cell><button className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5 py-2 rounded-lg' onClick={() => navigate('/foreign-currency-balance/usd')}>Open</button></Table.Cell>
                        </Table.Row>
                        <Table.Row className="dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-bold text-lg text-theme-primary flex">GBP <FaArrowRightLong className="mx-1 mt-1" /> United Kingdom</Table.Cell>
                            <Table.Cell>Lorem ipsum dolor sit amet sonsecterue. Lorem ipsum dolor sit amet consectetur.</Table.Cell>
                            <Table.Cell><button className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5 py-2 rounded-lg' onClick={() => navigate('/foreign-currency-balance/gbp')}>Open</button></Table.Cell>
                        </Table.Row>
                        <Table.Row className="bg-slate-200 dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-bold text-lg text-theme-primary flex">EUR <FaArrowRightLong className="mx-1 mt-1" /> Eurozone</Table.Cell>
                            <Table.Cell>Lorem ipsum dolor sit amet sonsecterue. Lorem ipsum dolor sit amet consectetur.</Table.Cell>
                            <Table.Cell><button className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5 py-2 rounded-lg' onClick={() => navigate('/foreign-currency-balance/eur')}>Open</button></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
}

export default ForeignCurrencyBalance;