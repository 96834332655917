
import { Tabs } from 'flowbite-react';
import { toast } from 'react-toastify';
import TableTemplate from './TableTemplate';
import FormTemplate from './FormTemplate';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { roundValue } from '../utils/utils';

const theme = {
    "base": "flex flex-col gap-2",
    "tablist": {
        "base": "flex text-center",
        "styles": {
            "pills": "flex-wrap font-medium text-sm bg-headings pl-3 text-white rounded-lg space-x-2",
        },
        "tabitem": {
            "base": "flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500",
            "styles": {
                "pills": {
                    "base": "",
                    "active": {
                        "on": "rounded-lg bg-white/10 text-white",
                        "off": "rounded-lg hover:bg-white/10"
                    }
                },
            },
            "icon": "mr-2 h-5 w-5"
        }
    },
    "tabitemcontainer": {
        "base": "",
        "styles": {
            "default": "",
            "underline": "",
            "pills": "",
            "fullWidth": ""
        }
    },
    "tabpanel": "py-3"
}

const BuySell = ({ payment }) => {
    const [currencyRates, setCurrencyRates] = useState({});
    const [selectedCurrency, setSelectedCurrency] = useState(0);
    const [currencyName, setCurrencyName] = useState('');
    const [currencyAmount, setCurrencyAmount] = useState(0);
    const [convertedAmount, setConvertedAmount] = useState(0);
    const [commission, setCommission] = useState(0);
    const [commissionType, setCommissionType] = useState('per');
    const [cashHandlingType, setCashHandlingType] = useState('');
    const [commissionVal, setCommissionVal] = useState(payment ? 7 : 12.5);
    const [cashHandlingVal, setCashHandlingVal] = useState(0);
    const [cashHandling, setCashHandling] = useState(0);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [reverse, setReverse] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalConverted, setTotalConverted] = useState(0);
    const [totalCommission, setTotalCommission] = useState(0);
    const [totalCashHandling, setTotalCashHandling] = useState(0);
    const [totalPayable, setTotalPayable] = useState(0);
    const [totalReceived, setTotalReceived] = useState(0);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (transactions) {
            try {
                const saveData = await axios.post(
                    process.env.REACT_APP_API_URL + `addTransaction`, transactions
                )
                if (saveData.status === 200) {
                    toast.success('Data Saved Successfully...');
                    setTransactions([]);
                    setTotalAmount(0);
                    setTotalConverted(0);
                    setTotalCommission(0);
                    setTotalCashHandling(0);
                    setTotalPayable(0);
                    setTotalReceived(0);
                }
            } catch (error) {
                toast.error('Error: Data not saved.');
            }
        }
    }

    const handleTransactions = () => {
        if (selectedCurrency && currencyAmount && commissionVal && (!payment || (paymentMethod))) {
            setTransactions([...transactions, {
                'currency': currencyName,
                'amount': currencyAmount,
                'rate': parseFloat(selectedCurrency).toFixed(4),
                'converted': roundValue(convertedAmount).toFixed(2),
                'per': `${(commissionType === 'per' ? commissionVal : 0)}%`,
                'fixed': `${(commissionType === 'fixed' ? commissionVal : 0)}`,
                'cashHandling': `${cashHandlingVal}`,
                'commAmount': `${roundValue(commission).toFixed(2)}`,
                'commissionType': commissionType,
                'commission': commissionVal,
                'particular': '',
                'surPer': '',
                'surFixed': '',
                'surcharges': '',
                'payableAmount': `${ payment ? (roundValue(convertedAmount) + roundValue(commission) + roundValue(cashHandling)).toFixed(2) : (roundValue(convertedAmount) - roundValue(commission) - roundValue(cashHandling)).toFixed(2)}`,
                'cashParticular': paymentMethod,
                'paidAmount': '',
                'receivedAmount': paymentAmount,
                'action': '',
                'transactionMode': `${payment ? 'Sell' : 'Buy'}`,
                'isReverse': reverse,
            }]);
            toast.success('Success: click save and settle to save it');
            setCurrencyAmount(0);
            setCashHandlingVal(0);
            return true;
        } else {
            const invalidFields = [];
            if (!selectedCurrency) invalidFields.push('currency');
            if (!currencyAmount) invalidFields.push('amount');
            if (!commissionVal) invalidFields.push('commission');
            if (payment && !paymentMethod) invalidFields.push('mode of payment');

            if (invalidFields.length > 0) {
                const lastItem = invalidFields.pop();
                toast.error(`Invalid: ${invalidFields.join(', ')} ${invalidFields.length > 0 ? 'and' : ''} ${lastItem}`);
            }
            return false;
        }
    }

    const handleRemoveTransaction = (index) => {
        const removedIndex = transactions[index];
        // setTotalAmount((totalAmount) => (parseFloat(totalAmount) - parseFloat(removedIndex.currencyAmount)).toFixed(2));
        // setTotalConverted((totalConverted) => (parseFloat(totalConverted) - roundValue(removedIndex.convertedAmount)).toFixed(2));
        // setTotalCommission((totalCommission) => (parseFloat(totalCommission) - roundValue(removedIndex.commission)).toFixed(2));
        // setTotalCashHandling((totalCashHandling) => (parseFloat(totalCashHandling) - roundValue(removedIndex.cashHandling)).toFixed(2));
        // setTotalPayable(totalPayable => ((parseFloat(totalPayable) - ((payment && !reverse) || (!payment && reverse) ? (roundValue(removedIndex.convertedAmount) - roundValue(removedIndex.commission) - roundValue(removedIndex.cashHandling)) : (roundValue(removedIndex.convertedAmount) + roundValue(removedIndex.commission) + roundValue(removedIndex.cashHandling))))).toFixed(2));
        // setTotalReceived(totalReceived => (parseFloat(totalReceived) - parseFloat(removedIndex.paymentAmount)).toFixed(2));
        
        setTransactions((prevItems) => prevItems.slice(0, index).concat(prevItems.slice(index + 1)));
    };

    useEffect(() => {
        const fetchRates = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_URL + `getCurrencyRates`);
                const rates = response.data;
                // rates.AUD = 1;
                setCurrencyRates(rates);
            } catch (error) {
                console.log(error);
            }
        };
        fetchRates();
    }, [])

    useEffect(() => {
        setTotalAmount(0);
        setTotalConverted(0);
        setTotalCommission(0);
        setTotalCashHandling(0);
        setTotalPayable(0);
        setTotalReceived(0);
        transactions.forEach(row => {
            setTotalAmount((totalAmount) => (parseFloat(totalAmount) + parseFloat(row.amount)).toFixed(2));
            setTotalConverted((totalConverted) => (parseFloat(totalConverted) + parseFloat(row.converted)).toFixed(2));
            setTotalCommission((totalCommission) => (parseFloat(totalCommission) + parseFloat(row.commAmount)).toFixed(2));
            setTotalCashHandling((totalCashHandling) => (parseFloat(totalCashHandling) + parseFloat(row.cashHandling)).toFixed(2));
            setTotalPayable(totalPayable => ((parseFloat(totalPayable) + (payment ? (parseFloat(row.converted) + parseFloat(row.commAmount) + parseFloat(row.cashHandling)) : (parseFloat(row.converted) - parseFloat(row.commAmount) - parseFloat(row.cashHandling))))).toFixed(2));
            setTotalReceived(totalReceived => (parseFloat(totalReceived) + parseFloat(row.receivedAmount)).toFixed(2));
        });
    }, [transactions]);

    return (
        <Tabs aria-label="Pills" theme={theme} style={'pills'} className='w-full mt-6'>
            <Tabs.Item active title="Entry Form" className='text-xl'>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                    <div className="grid grid-cols-5 gap-10">
                        <div className='col-span-3 p-5' style={{ backgroundColor: payment ? 'rgba(255,255,0,0.4)' : 'rgba(0,255,255,0.2)' }}>
                            <FormTemplate
                                handleSubmit={handleSubmit}
                                rates={currencyRates}
                                selectedCurrency={selectedCurrency}
                                setSelectedCurrency={setSelectedCurrency}
                                currencyName={currencyName}
                                setCurrencyName={setCurrencyName}
                                currencyAmount={currencyAmount}
                                setCurrencyAmount={setCurrencyAmount}
                                convertedAmount={convertedAmount}
                                setConvertedAmount={setConvertedAmount}
                                commission={commission}
                                commissionType={commissionType}
                                setCommissionType={setCommissionType}
                                commissionVal={commissionVal}
                                setCommissionVal={setCommissionVal}
                                cashHandlingType={cashHandlingType}
                                setCashHandlingType={setCashHandlingType}
                                cashHandlingVal={cashHandlingVal}
                                setCashHandlingVal={setCashHandlingVal}
                                setCommission={setCommission}
                                cashHandling={cashHandling}
                                setCashHandling={setCashHandling}
                                setPaymentAmount={setPaymentAmount}
                                setPaymentMethod={setPaymentMethod}
                                handleTransactions={handleTransactions}
                                totalConverted={totalConverted}
                                totalAmount={totalAmount}
                                totalReceived={totalReceived}
                                totalCommission={totalCommission}
                                totalPayable={totalPayable}
                                showPayment={payment}
                                transactions={transactions}
                                reverse={reverse}
                                setReverse={setReverse}
                            />
                        </div>
                        <div className='col-span-2 p-5 font-medium' style={{ backgroundColor: payment ? 'rgba(255,255,0,0.4)' : 'rgba(0,255,255,0.2)' }}>
                            <div className="grid grid-cols-2 gap-4 border-b border-gray-600 mx-8 p-3">
                                <div className='text-lg text-black'>
                                    Converted Amount:
                                </div>
                                <div className='text-headings text-xl'>
                                    AUD {roundValue(convertedAmount).toFixed(2)}
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 border-b border-gray-600 mx-8 p-3">
                                <div className='text-lg text-black'>
                                    Commission Amount:
                                </div>
                                <div className='text-headings text-xl'>
                                    AUD {roundValue(commission).toFixed(2)}
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 border-b border-gray-600 mx-8 p-3">
                                <div className='text-lg text-black'>
                                    Cash Handling Amount:
                                </div>
                                <div className='text-headings text-xl'>
                                    AUD {roundValue(cashHandling).toFixed(2)}
                                </div>
                            </div>
                            {/* <div className="grid grid-cols-2 gap-4 border-b border-gray-600 mx-8 p-3">
                                <div className='text-lg text-black'>
                                    Surcharge Amount:
                                </div>
                                <div className='text-headings text-xl'>
                                    AUD 0.00
                                </div>
                            </div> */}
                            <div className="grid grid-cols-2 gap-4 border-b border-gray-600 mx-8 p-3">
                                <div className='text-lg text-black'>
                                    Cash Amount:
                                </div>
                                <div className='text-headings text-xl'>
                                    AUD { payment ? (roundValue(convertedAmount) + roundValue(commission) + roundValue(cashHandling)).toFixed(2) : (roundValue(convertedAmount) - roundValue(commission) - roundValue(cashHandling)).toFixed(2)}
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 border-b border-gray-600 mx-8 p-3">
                                <div className='text-lg text-black'>
                                    EFTPOS Amount:
                                </div>
                                <div className='text-headings text-xl'>
                                    AUD 0.00
                                </div>
                            </div>
                            {/* <div className="grid grid-cols-3 gap-1 mx-8 p-3 mt-5">
                                <div className='text-lg text-black'>
                                    Reverse:
                                </div>
                                <div className='text-headings text-xl'>
                                    $ 10025.56
                                </div>
                                <div>
                                    <Button type='button' className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5'>Reverse</Button>
                                </div>
                            </div> */}
                            <div className="grid grid-cols-2 gap-1 mx-8 p-3 mt-10">
                                <div className='text-lg text-black'>
                                    Received Amount:
                                </div>
                                <div className='text-headings text-xl'>
                                    AUD { payment ? (roundValue(convertedAmount) + roundValue(commission) + roundValue(cashHandling)).toFixed(2) : (roundValue(convertedAmount) - roundValue(commission) - roundValue(cashHandling)).toFixed(2)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Tabs.Item>
            <Tabs.Item title="Transactions">
                <div className="text-sm text-gray-500 dark:text-gray-400">
                    <TableTemplate transactions={transactions} setTransactions={setTransactions} handleRemoveTransaction={handleRemoveTransaction} totalConverted={totalConverted} totalCommission={totalCommission} totalCashHandling={totalCashHandling} totalPayable={totalPayable} totalReceived={totalReceived} />
                </div>
            </Tabs.Item>
        </Tabs>
    );
}

export default BuySell;
