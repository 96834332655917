import React from "react";
import { Table } from "flowbite-react";
import { Route, useNavigate } from 'react-router-dom';
import BulkCoins from "../shared/localCurrencyBalance/BulkCoins";
const LocalCurrencyBalance = () => {
    const navigate = useNavigate();
    return (
        <div className="">
            {/* <Route path="/bulk-coins" component={BulkCoins} /> */}
            {/* <Route path="/bulk-coins-balance" component={BulkCoinsBalance} />
            <Route path="/notes-balance" component={NotesBalance} />
            <Route path="/coins-in-tray-balance" component={CoinsInTrayBalance} /> */}
            <div className="">
                <h1 className="text-headings text-3xl font-medium mt-10">Local Currency Balance</h1>
            </div>
            <div className="overflow-x-auto mt-12">
                <Table>
                    <Table.Head>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Balance Inquiry</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Description</Table.HeadCell>
                        <Table.HeadCell className='bg-theme-secondary text-white font-medium text-lg'>Action</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        <Table.Row className="bg-slate-200 dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-bold text-lg text-theme-primary">Safe</Table.Cell>
                            <Table.Cell>Lorem ipsum dolor sit amet sonsecterue. Lorem ipsum dolor sit amet consectetur.</Table.Cell>
                            <Table.Cell><button className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5 py-2 rounded-lg' onClick={() => navigate('/safe')}>Open</button></Table.Cell>
                        </Table.Row>
                        <Table.Row className="dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-bold text-lg text-theme-primary">Bulk Coins</Table.Cell>
                            <Table.Cell>Lorem ipsum dolor sit amet sonsecterue. Lorem ipsum dolor sit amet consectetur.</Table.Cell>
                            <Table.Cell><button className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5 py-2 rounded-lg' onClick={() => navigate('/bulk-coins')}>Open</button></Table.Cell>
                        </Table.Row>
                        <Table.Row className="bg-slate-200 dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-bold text-lg text-theme-primary">Notes</Table.Cell>
                            <Table.Cell>Lorem ipsum dolor sit amet sonsecterue. Lorem ipsum dolor sit amet consectetur.</Table.Cell>
                            <Table.Cell><button className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5 py-2 rounded-lg' onClick={() => navigate('/notes')}>Open</button></Table.Cell>
                        </Table.Row>
                        <Table.Row className="dark:border-gray-700 dark:bg-gray-800">
                            <Table.Cell className="whitespace-nowrap font-bold text-lg text-theme-primary">Coins in Tray</Table.Cell>
                            <Table.Cell>Lorem ipsum dolor sit amet sonsecterue. Lorem ipsum dolor sit amet consectetur.</Table.Cell>
                            <Table.Cell><button className='bg-gradient-to-br from-theme-primary to-theme-secondary text-white px-5 py-2 rounded-lg' onClick={() => navigate('/coins-in-tray')}>Open</button></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        </div>
    )
}

export default LocalCurrencyBalance;